<script setup lang="ts">
</script>

<template>
  <table class="protocols">
    <tr>
      <th>Имя для терминала</th>
      <th>IP адрес</th>
      <th>Принимающий сервис</th>
      <th>Имя для входа</th>
    </tr>
    <tr>
      <td>hw1.logexpert.ru</td>
      <td>194.87.93.67</td>
      <td>LogExpert</td>
      <td>gis.logexpert.ru</td>
    </tr>
    <tr>
      <td>hw2.logexpert.ru</td>
      <td>194.87.93.192</td>
      <td>WialonLocalRent, WH</td>
      <td>w1.logexpert.ru</td>
    </tr>
    <tr>
      <td>hw3.logexpert.ru</td>
      <td>195.133.147.4</td>
      <td>WialonLocal</td>
      <td>w2.logexpert.ru</td>
    </tr>
    <tr>
      <td>hw4.logexpert.ru</td>
      <td>195.133.147.4</td>
      <td>WialonPro</td>
      <td>w3.logexpert.ru</td>
    </tr>
  </table>
</template>

<style scoped>
td, th{
  border: 1px black solid;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: normal;
  padding-top: 3px;
  text-align: left;
}
th{
  font-weight: bold;
}
.protocols{
  border-spacing: 0;
  border-collapse: collapse;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}
.protocols th{
  background-color: #E6E6E6;
}
</style>