<template>
  <div class="home">
    <h1>Данный адрес предназначен для настройки трекеров, подключаемых к облачному роутеру.</h1>
    <nameTable/>
    <h2>Старая схема подключения: hw.logexpert.ru; ip: 37.46.129.80</h2>
    <protocolTable/>
    <schema/>
    За дополнительной информацией обращайтесь на основной сайт logexpert.ru
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import schema from "@/components/schema.vue";
import protocolTable from "@/components/protocol-table.vue";
import nameTable from "@/components/name-table.vue";
export default defineComponent({
  name: 'HomeView',
  components: {
    schema,
    protocolTable: protocolTable,
    nameTable
  },
});
</script>

<style>
body {
  text-align: center;
  font-family: verdana, sans-serif;
  font-style: normal;
}
</style>
```
