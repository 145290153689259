<script>
import VueMermaidString from 'vue-mermaid-string'
import endent from 'endent'
export default {
  components: {
    VueMermaidString,
  },
  computed: {
    // equals graph TD\n  A --> B
    diagram: () => endent`
      graph TD
        style A fill:#88DD4C,stroke:#000000,stroke-width:2px;
        style G fill:#88DD4C,stroke:#000000,stroke-width:2px;
        style B fill:#7800FF,stroke:#FF4F12,stroke-width:2px,color:#ffffff;
        style C fill:#ffffff,stroke:#F44639,stroke-width:2px,color:#478CFF;
        style D fill:#89127C,stroke:#26579F,stroke-width:2px,color:#ffffff;
        style E fill:#17A2B8,stroke:#117A8B,stroke-width:2px,color:#ffffff;
        style F fill:#ffffff,stroke:#B4001F,stroke-width:2px,color:#FC001F;
        A -->|данные приборов| C[Виалон 193.193.165.165]
        A -->|данные приборов| F[Flespi 165.37]
        A[Облачный роутер 37.46.129.80] -->|данные приборов, http| B[Ростелеком 82.151.126.4]
        B -->|данные приборов, http| E[Гис]
        B -->|данные приборов, http| D[Про]
        G -->|данные приборов| C[Виалон 193.193.165.165]
        G -->|данные приборов| F[Flespi 165.37]
        G[Облачный роутер 194.87.207.64] -->|данные приборов, http| B[Ростелеком 82.151.126.4]
    `,
  },
}
</script>

<template>
  <h2>Принципиальная схема взаимодействия:</h2>
  <vue-mermaid-string :value="diagram" />
  <div>Таким образом, в пуле портов не должно быть пересечений между протоколами облачного роутера. Однако допускается
    повторение портов, для ПРО, если они направлены напрямую на Ростелеком</div>
</template>

<style scoped>

</style>